.gnlbl {
    font-weight: bold;
    font-size: 15px;
    color: $heritage-blue-color;
}

.gnslbl {    
    font-size: 14px;
    color: $heritage-blue-color;
}

.cslbl {
    font-weight: bold;
    font-size: 15px;
    color: $yellow-reflector-color;
    margin-left: 10px;
}

.gylbl {
    font-weight: bold;
    font-size: 15px;
    color: $medium-gray-color;
    margin-left: 10px;
}

.grlbl {
    font-weight: bold;
    font-size: 15px;
    color: $natural-color;    
}


input.cslbl, select.cslbl {
    margin: 0;
}

.gnlbll {
    font-weight: bold;
    font-size: 16px;
    color: $heritage-blue-color;
    margin-left: 10px;
}

.gnlblxl {
    font-weight: bold;
    font-size: 18px;
    color: $heritage-blue-color;
}

.cslbll {
    font-weight: bold;
    font-size: 16px;
    color: $yellow-reflector-color;
    margin-left: 10px;
}

.wslbll {
    font-weight: bold;
    font-size: 16px;
    color: $strength-color;
    margin-left: 10px;
}


.wslblxl {
    font-weight: bold;
    font-size: 18px;
    color: $strength-color;
    margin-left: 10px;
}

.cslblxl {
    font-weight: bold;
    font-size: 18px;
    color: $yellow-reflector-color;
    margin-left: 10px;
}


