/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import "styles/cpraBranding";
@import "styles/app.scss";


.em-message {
    float: right;
    color: $strength-color;
    padding-left: 10px;
    font-size: 13px;
    font-weight: bold;
}

.card-header {
    color: $heritage-blue-color;
    background-color: #F3F5F6 ;    
}


.tooltip-custom-class .tooltip-inner {
    background-color: $heritage-color;
    font-size:120%;    
}

.tooltip-custom-class .arrow::before {
    border-top-color: $heritage-color;
}

/*validation*/

.ng-valid[required],
.ng-valid.required {
  border: 1px solid #42A948;
}

.error {
  color: #ff0000;
}

.success {
  color: green;
}

.contact-message {
  text-align: center;
}