$light-gray-color: rgb(179,179,179);
$medium-gray-color: rgb(145,143,146);
$dark-gray-color: rgb(85,87,90);
$confident-color: rgb(29,29,29);
$heritage-color: rgb(0, 149, 217);
$heritage-blue-color: rgb(20, 95, 126);
$built-color: rgb(248,218,64);
$fluidity-color: rgb(0,169,228);
$strength-color: rgb(228,31,0);
$natural-color: rgb(13,166,66);
$yellow-reflector-color: #f0b31d;
$space-blue-color: #045594;