@import '_cpraBranding.scss';

// Bootstrap overrides
$blue: $fluidity-color;
$yellow: $built-color;
$light: $light-gray-color;
$dark: $dark-gray-color;

$brand-primary: $heritage-color;
$brand-warning: $built-color;
$brand-danger: $strength-color;
$brand-info: $fluidity-color;
$card-cap-bg: $light-gray-color;

$text-color: $confident-color;

$state-success-text: $natural-color;
/*$state-success-bg: lighten($state-success-text, 50%);
$state-success-border: darken(adjust-hue($state-success-bg, -10), 5%);*/

$state-warning-text: $brand-warning;
/*$state-warning-bg: lighten($state-warning-text, 50%);
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%);*/

$state-danger-text: $brand-danger;
/*$state-danger-bg: lighten($state-danger-text, 50%);
$state-danger-border: darken(adjust-hue($state-danger-bg, -10), 5%);*/

$state-info-text: $brand-info;
/*$state-info-bg: lighten($state-info-text, 50%);
$state-info-border: darken(adjust-hue($state-info-bg, -10), 5%);*/

$primary: $brand-primary;

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; // remove the gap so it doesn't close
 }

 .custom-align {
    padding-top: 6px;
    margin-bottom: 0;
    vertical-align: middle;
  }

  table {
   empty-cells:hide;
 }

  td {
   text-align: center;
   /* center checkbox horizontally */
   vertical-align: middle;
   /* center checkbox vertically */
 }

 .tooltip-info .tooltip-inner {
  color: white;
  background-color: $space-blue-color;
  border: 1px solid $heritage-blue-color;
}
.tooltip-info.tooltip.bs-tooltip-top .arrow:before {
  border-top-color:$heritage-blue-color;
}
.tooltip-info.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: $heritage-blue-color;
}
.tooltip-info.tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: $heritage-blue-color;
}
.tooltip-info.tooltip.bs-tooltip-left .arrow:before {
  border-left-color: $heritage-blue-color;
}

 $custom-control-indicator-size: 2rem;

 ::ng-deep .pointer-cursor{
  cursor: pointer !important; 
}