/* You can add global styles to this file, and also import other style files */
@import "cprabootstrap";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "spinner";
@import "utils";
@import "rtl";
@import "responsive";
@import "forms";


@media print {
    .breadcrumb {
        display: none !important;
    }
}

body { padding-top: 100px; }